<template>
  <!-- 店铺列表
        除了超级终端外其他店铺使用时只显示自己店铺的一条信息
        超级终端可查看所有店铺的信息，可以进行编辑
   -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="店铺列表"
    ></el-page-header>

    <el-button
      v-if="storeShow"
      class="created-store"
      type="primary"
      @click="storeLine"
      >点击开通店铺</el-button
    >
    <el-button
      v-if="storeShow"
      class="created-store"
      type="primary"
      @click="createStoreNum"
      >创建店铺账号</el-button
    >
    <div class="select-container">
      <div class="product-table">
        <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          border
          style="width: 100%; max-width: 100%"
          :cell-style="csllStyle"
        >
          <el-table-column
            fixed
            type="index"
            :index="pagesize * page_num - pagesize + 1"
            label="序号"
            width="60%"
          >
          </el-table-column>
          <el-table-column prop="storeFlag" label="店铺编号" width="280%">
          </el-table-column>
          <el-table-column prop="title" label="店铺名称" width="200%">
          </el-table-column>
          <el-table-column prop="_site" label="地址" width="200%">
          </el-table-column>
          <el-table-column prop="turnover" label="营业额" width="100%">
          </el-table-column>
          <el-table-column prop="phonenum" label="联系电话" width="260%">
          </el-table-column>
          <el-table-column prop="_startTime" label="营业时间" width="100%">
          </el-table-column>
          <el-table-column prop="_endTime" label="歇业时间" width="100%">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180%">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页底部 -->
        <el-pagination
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data: function () {
    return {
      tableData: "",
      // 是否加载数据
      loading: true,
      currentPage: 1,
      pagesize: 10,
      totalNum: 0,
      page_num: 1,
      storeShow: false,
      storeKey: "",
    };
  },
  created() {
    this.storeKey = secret.Decrypt(localStorage.getItem("user_key"));
    this.storeKey === "yhl001656847198012"
      ? (this.storeShow = true)
      : (this.storeShow = false);

    let pageNum = 1;
    let list = true;
    let storeKey = this.storeKey;
    // 获取值
    axios
      .post(this.GLOBAL.api + "/store", { list, pageNum, storeKey })
      .then((res) => {
        // console.log("店铺1：", res);
        this.loading = false; // loading
        this.totalNum = res.data.total[0].count;
        this.tableData = res.data.val;
      });
    // if (this.storeKey === "yhl001656847198012") {
    //   this.tableData.forEach((ele) => {
    //     if (ele.storeFlag === "yhl001656847198012") {
    //       localStorage.setItem("self_store", ele.title);
    //     }
    //   });
    // } else {
    //   localStorage.setItem("self_store", this.tableData[0].title);
    // }
  },
  // 监听器
  watch: {
    csllStyle(row) {
      // 监听tabel数据进行操作
    },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    storeLine() {
      this.$router.push("/created/store");
    },
    createStoreNum() {
      this.$router.push("/store/create/user");
    },
    // 编辑
    handleClick(row) {
      const id = secret.Encrypt(JSON.stringify(row));
      this.$router.push(`/fix/store/${id}`);
    },
    // 分页处理
    handleCurrentChange(val) {
      let pageNum = val;
      this.page_num = val;
      let all = true;
      let storeKey = this.storeKey;
      axios
        .post(this.GLOBAL.api + "/creat/contract", { all, pageNum, storeKey })
        .then((res) => {
          this.totalNum = res.data.total[0].count;
          this.tableData = res.data.val;
        });
    },
    // contractMoney
    csllStyle(row) {
      // // 监听tabel数据进行操作，如颜色警告
      // console.log('row---',row);
      if (row.row.storeFlag === "yhl001656847198012") {
        return "backgroundColor:#e6a23c;color:#FFF";
        // row.column.label = '正常'
      }
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.select {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.selectVal {
  display: flex;
  width: 35%;
  margin-left: 30px;
}
.product-table {
  width: 100%;
}
.el-table {
  margin-top: 2%;
}
.created-store {
  margin: 10px 0 0 50px;
}
</style>